

































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { v4 as uuid } from 'uuid';
import { last, orderBy, sortBy, uniqBy, toLower, sumBy, isUndefined, cloneDeep } from 'lodash';
import { plainToClass } from 'class-transformer';
import { HardBreak } from 'tiptap-vuetify';
import { EditorView } from 'tiptap';
import { UppyFile } from '@uppy/core';
import CrmChatDialog from '@/components/crm/chat-dialog.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import ConversationService from '@/services/crm/conversation.service';
import CrmChatListItem from '@/components/crm/chat-list-item.vue';
import ConversationModel from '@/models/crm/conversation.model';
import ConversationQuickAnswerModel from '@/models/crm/conversation-quick-answer.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import WysiwygEditor from '@/components/wysiwyg-editor.vue';
import ConversationMessageModel from '@/models/crm/conversation-message.model';
import ConversationMessageOriginModel from '@/models/crm/conversation-message-origin.model';
import { ConversationMessageOriginEnum } from '@/enums/crm/conversation-message-origin.enum';
import ConversationMessageTypeModel from '@/models/crm/conversation-message-type.model';
import { ConversationMessageTypeEnum } from '@/enums/crm/conversation-message-type.enum';
import { ConversationMessageStatusEnum } from '@/enums/crm/conversation-message-status.enum';
import ConversationMessageStatusModel from '@/models/crm/conversation-message-status.model';
import UserModel from '@/models/user.model';
import dayjs from '@/plugins/dayjs';
import EnterInterceptor from '@/utils/tiptap-extensions/enter-interceptor';
import ClientModel from '@/models/crm/client.model';
import ConversationDepartmentModel from '@/models/crm/conversation-department.model';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import ContentDialog from '@/components/content-dialog.vue';
import CrmBlockNumberForm from '@/components/crm/chat-block-number-form.vue';
import CrmChatSelectTemplateForm from '@/components/crm/chat-select-template-form.vue';
import CrmChatSelectQuickAnswerForm from '@/components/crm/chat-select-quick-answer-form.vue';
import ConversationTemplateModel from '@/models/crm/conversation-template.model';
import CrmChatContactForm from '@/components/crm/chat-contac-form.vue';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import CrmChatCloseForm from '@/components/crm/chat-close-form.vue';
import CrmChatSelectAttendantForm from '@/components/crm/chat-select-attendant-form.vue';
import CrmChatSelectDepartmentForm from '@/components/crm/chat-select-department-form.vue';
import CrmChatSelectEmoticonForm from '@/components/crm/chat-select-emoticon.vue';
import CrmChatHistoryViewer from '@/components/crm/chat-history-viewer.vue';
import CrmChatMediaPreview from '@/components/crm/chat-media-preview.vue';
import { IChatSendMediaItem } from '@/interfaces/crm/chat-send-media-item.interface';
import AudioRecorder from '@/components/audio-recorder.vue';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import ConversationUserPermissionsModel from '@/models/crm/conversation-user-permissions.model';
import RouterService from '@/services/router.service';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ContactService from '@/services/crm/contact.service';
import { FileHelper } from '@/utils/helpers/file-helper';
import ConversationMessageMediaModel from '@/models/crm/conversation-message-media.model';
import OriginModel from '@/models/crm/origin.model';
import GroupModel from '@/models/crm/group.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import ContactModel from '@/models/crm/contact.model';
import { ICreateConversation } from '@/interfaces/crm/create-conversation.interface';
import Tooltip from '@/components/tooltip.vue';
import SettingsService from '@/services/crm/settings.service';
import SettingsModel from '@/models/crm/settings.model';
import { UserTypeEnum } from '@/enums/crm/user-type.enum';
import WhatsappBlockModel from '@/models/crm/whatsapp-block.model';
import ProspectService from '@/services/crm/prospect.service';
import ClientService from '@/services/crm/client.service';
import CrmChatSelectClientProspectForm from './chat-select-client-prospect-form.vue';
import ProspectModel from '@/models/crm/prospect.model';

type DataGridFilterConfig = {
  contactTypes: ISelectOption<string | undefined>[] | null;
  attendenceQueues: ConversationDepartmentModel[] | null;
};

type ChatVariables = {
  isOpen: boolean;
  isUploadingFile: boolean;
  media: UppyFile[];
};

interface IChatListItem {
  loading?: boolean;
  id: number;
  conversation: ConversationModel;
  unreadCounter?: number;
}

interface IEditorMessage {
  message: string;
  action?: CallableFunction;
}

interface IChatContactFormModel {
  description: string | null;
  mistake: boolean;
  justified: boolean;
  origin: OriginModel | null;
  historyType: HistoryTypeModel | null;
  groupArea: GroupModel | null;
  consolidateSale: boolean;
  contact: ContactModel | null;
}

interface ISelectOption<T> {
  id: string;
  code: T;
  description: string;
}

interface ITransferData {
  department: ConversationDepartmentModel | null;
  attendant: UserModel | null;
}

@Component({
  components: {
    WysiwygEditor,
    AudioRecorder,
    ContentDialog,
    ConfirmationDialog,
    CrmChatDialog,
    CrmChatListItem,
    CrmBlockNumberForm,
    CrmChatSelectTemplateForm,
    CrmChatSelectQuickAnswerForm,
    CrmChatContactForm,
    CrmChatCloseForm,
    CrmChatSelectAttendantForm,
    CrmChatSelectEmoticonForm,
    CrmChatHistoryViewer,
    CrmChatMediaPreview,
    Tooltip,
    CrmChatSelectDepartmentForm,
    CrmChatSelectClientProspectForm,
  },
})
export default class CrmChat extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmConversationService)
  protected conversationService!: ConversationService;

  @inject(InjectionIdEnum.CrmContactService)
  protected contactService!: ContactService;

  @inject(InjectionIdEnum.CrmClientService)
  protected clientService!: ClientService;

  @inject(InjectionIdEnum.CrmProspectService)
  protected prospectService!: ProspectService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @Prop({ required: true })
  conversationUserPermission!: ConversationUserPermissionsModel;

  @Prop({ required: true })
  userContactInfo!: UserContactInfo;

  @Prop()
  client!: ClientModel;

  @Prop({ required: true, default: 200 })
  width!: string | number;

  @Prop({ required: true, default: '100vh' })
  height!: string | number;

  @Prop()
  createConversation!: ICreateConversation;

  @Prop({ default: false })
  drawer!: boolean;

  settings: SettingsModel | null = null;

  filters: DataGridFilterConfig = {
    contactTypes: null,
    attendenceQueues: null,
  };

  loading = false;

  expand = false;

  tab: string | null = null;

  chat: ChatVariables = { isOpen: false, isUploadingFile: false, media: [] };

  btnPaste = false;

  activeConversation: ConversationModel | null = null;

  private existingContacts: ContactModel[] = [];

  chatList: IChatListItem[] = [];

  blockedNumbers: string[] = [];

  incrementalChatList: IChatListItem[] = [];

  editorContent: string | null = null;

  editorExtensions = [HardBreak];

  editorNativeExtensions = [new EnterInterceptor(this.onEditorKeydownEnter)];

  cachedEditorContent = new Map<number, string | null>();

  openingConversation = false;

  transferingConversation = false;

  searchKeyword = '';

  searching = false;

  filtering = false;

  loadingDialog = false;

  cancelOperation = true;

  dialogConfig: IKeyValue<IDialogConfig> = {
    blockNumber: {
      show: false,
    },
    selectTemplate: {
      show: false,
    },
    selectQuickAnswer: {
      show: false,
    },
    selectEmoticon: {
      show: false,
    },
    mediaPreview: {
      show: false,
      files: null,
      type: null,
    },
    contact: {
      show: false,
      conversation: null,
      closeConversationOnSave: false,
      hasExistingContacts: false,
    },
    closeConversation: {
      show: false,
      mistake: false,
      justified: false,
      transfer: false,
      newAttendant: null,
    },
    selectAttendant: {
      show: false,
    },
    history: {
      show: false,
      conversation: null,
    },
    closeAction: {
      show: false,
      transfer: false,
      newAttendant: null,
      onChoice: () => {},
    },
    dynamicUraMode: {
      show: false,
    },
    openConversationAndSelectDepartment: {
      show: false,
    },
    selectClientProspect: {
      show: false,
    },
  };

  btnCloseNotSavingContact = '';

  templates: ConversationTemplateModel[] = [];

  photoAndVideo: File[] = [];

  document: File[] = [];

  private lastEditorKeydownPosition = 0;

  private currentSelection: any | null = null;

  private readonly allowedMediaLength = 30;

  private isRecordingAudio = false;

  private alreadyLoadTabAll = false;

  closeChatmodel: IChatContactFormModel = {
    description: null,
    historyType: null,
    origin: null,
    groupArea: null,
    contact: null,
    mistake: false,
    justified: false,
    consolidateSale: false,
  };

  currentPage = 1;

  disableForm = false;

  refreshLocatedContacts = '';

  checkLocatedContactsAndOpenSelection = '';

  showDialogConfirmationOptin = false;

  @Watch('chat.isOpen')
  async onChatChange(value: boolean): Promise<void> {
    if (!value) {
      this.chat.isUploadingFile = false;
      setTimeout(() => {
        this.activeConversation = null;
      }, 350);
    }
  }

  @Watch('chat.isUploadingFile')
  onUploadChange(value: boolean): void {
    const docs = new Array<IChatSendMediaItem>();
    if (this.chat.media.length > 0 && !value && this.btnPaste) {
      this.chat.media.forEach((media) => {
        let internalType = ConversationMessageTypeEnum.Document;
        if (media.type?.includes('image')) {
          internalType = ConversationMessageTypeEnum.Image;
        } else if (media.type?.includes('video')) {
          internalType = ConversationMessageTypeEnum.Video;
        } else if (media.type?.includes('audio')) {
          internalType = ConversationMessageTypeEnum.Audio;
        }
        const doc: IChatSendMediaItem = {
          file: media.data,
          dataUri: '',
          type: internalType,
          caption: media.meta && media.meta.description ? (media.meta.description as string) : (media.name as string),
          filename: media.name,
        };
        docs.push(doc);
      });
    }
    this.chat.media = [];
    this.btnPaste = !this.btnPaste;
    this.onSendMedia(docs);
  }

  get isTabAllSelected(): boolean {
    return this.tab === 'tab-all';
  }

  get contactFormDialogTitle(): string {
    let titleKey!: string;
    if (this.dialogConfig.contact.hasExistingContacts) {
      titleKey = 'crm.chat.dialog.contact.existingContactsTitle';
    } else {
      titleKey = 'crm.chat.dialog.contact.title';
    }
    return `${this.$t(titleKey)}`;
  }

  @Watch('tab')
  onTabChange(tab: string): void {
    if (tab === 'tab-all' && !this.alreadyLoadTabAll) {
      if (!this.searching) {
        this.alreadyLoadTabAll = true;
      }
      this.onRefresh();
    }
  }

  onChangeDepartment(event: ITransferData): void {
    if (this.activeConversation) {
      this.activeConversation.departamento.id = event.department?.id as number;
    }
  }

  @Watch('$store.state.createConversation')
  async onCreateConversationChange(value: ICreateConversation): Promise<void> {
    if (!value || !this.client) {
      return;
    }
    let alreadyExistentConversation: ConversationModel | null;
    try {
      alreadyExistentConversation = await this.conversationService.getConversationByWaID(value.waContact.waId);
    } catch (e) {
      alreadyExistentConversation = null;
    }
    if (
      alreadyExistentConversation !== null
      && !this.chatList.some((x) => x.conversation.id === alreadyExistentConversation?.id)
    ) {
      const chatItem = {} as IChatListItem;
      CrmChat.populateChatListItem(chatItem, alreadyExistentConversation);
      this.chatList = [...this.chatList, chatItem];
    }

    if (alreadyExistentConversation !== null) {
      this.$store.commit('openConversation', alreadyExistentConversation, {
        root: true,
      });
      return;
    }

    this.$store.commit('createConversation', null, { root: true });

    this.dialogConfig.openConversationAndSelectDepartment.show = true;

    const conversation = new ConversationModel();
    conversation.numeroWhatsapp = value.waContact.waId;
    conversation.numeroContato = value.waContact.input;
    conversation.cnpj = this.client.cnpjCpf;
    conversation.messages = [];

    const department = new ConversationDepartmentModel();
    department.id = 1;
    conversation.departamento = department;

    const attendant = new UserModel();
    attendant.id = this.userContactInfo.id;
    attendant.nome = this.userContactInfo.nome;
    conversation.atendente = attendant;

    conversation.cliente = this.client;
    if (this.client != null) {
      if (this.client.tipo === 'Prospect') {
        conversation.tipo = ConversationTypeEnum.Prospect;
      } else {
        conversation.tipo = ConversationTypeEnum.Client;
      }
    }

    if (value.contact) {
      conversation.contato = value.contact;
    }

    this.activeConversation = conversation;
    this.chat.isOpen = true;
    this.drawer = true;
  }

  @Watch('$store.state.openConversation')
  async onOpenChat(conversation: ConversationModel): Promise<void> {
    if (!conversation) {
      return;
    }

    this.$store.commit('openConversation', null, { root: true });

    this.activeConversation = conversation;
    this.reloadConversation(this.activeConversation?.id as number);

    this.tab = 'tab-all';
    setTimeout(() => {
      this.chat.isOpen = true;
    }, 2000);
    this.drawer = true;
  }

  async mounted(): Promise<void> {
    this.loading = true;
    try {
      await this.loadSettings();
      await this.loadBlockedNumbers();
      await this.loadBtnCloseNotSavingContact();
      await this.loadTemplates();
      if (this.createConversation) {
        this.$store.commit('createConversation', this.createConversation, { root: true });
      }

      await this.loadConversations();
      await this.conversationService.getCachedCommServiceToken();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      this.loading = false;
    }

    this.subscribeToActions();
  }

  unmounted(): void {
    this.unsubscribeActions();
  }

  onRetrySendText(message: ConversationMessageModel): void {
    this.editorContent = message.message;
    if (this.activeConversation) {
      this.onSendMessage(this.activeConversation);
    }
  }

  onRetrySendTemplate(template: ConversationTemplateModel): void {
    this.onSelectTemplate(template);
  }

  async onSendMessage(conversation: ConversationModel): Promise<void> {
    let parsedMessage = this.editorContent || '';
    parsedMessage = parsedMessage.replace(/<\/p><p>/gi, '\n');
    parsedMessage = parsedMessage.replace(/<br>/gi, '\n');
    parsedMessage = parsedMessage.replace(/<(?:.|\s)*?>/g, '');
    if (!parsedMessage || !this.activeConversation) {
      return;
    }

    if (parsedMessage.length > 4096) {
      this.$notify.error(
        this.$t('crm.chat.messages.limitMessageCharsExceeed', {
          limit: 4096,
          quantity: parsedMessage.length,
        }),
      );
      return;
    }

    const tempMessage = CrmChat.createMessageInstance(
      new Date().getTime(),
      parsedMessage,
      ConversationMessageOriginEnum.Outgoing,
      ConversationMessageTypeEnum.Text,
      ConversationMessageStatusEnum.Temporary,
    );

    const chatListItem = this.chatList.find((x) => x.conversation?.id === conversation.id);
    if (chatListItem) {
      chatListItem.conversation.messages.push(tempMessage);
    }

    try {
      // If conversation is not started, open it before sends first message
      if (!conversation.atendente || !conversation.atendente.id) {
        await this.openConversation(conversation, this.userContactInfo.id);
      }

      this.conversationService
        .sendTextMessage(conversation, parsedMessage, true)
        .then((result) => {
          if (chatListItem && chatListItem.conversation.id === conversation.id) {
            const tempIndex = chatListItem.conversation.messages.findIndex((x) => x.id === tempMessage.id);
            if (tempIndex > -1) {
              chatListItem.conversation.messages.splice(tempIndex, 1, result);
            }
          }
        })
        .catch((err) => {
          this.$notify.error(err && (err as Error).message, 5000);
          tempMessage.status.code = ConversationMessageStatusEnum.TemporaryInError;
        });

      this.editorContent = null;
      this.lastEditorKeydownPosition = 0;

      this.storeEditorContentInCache(conversation);
    } catch (err) {
      this.$notify.error(err && (err as Error).message, 5000);
    }
  }

  onEditorKeydownEnter(): void {
    if (this.activeConversation) {
      this.onSendMessage(this.activeConversation);
    }
  }

  async onChatItemClick(item: IChatListItem, markAsRead = false): Promise<void> {
    if (item.conversation && this.activeConversation?.id === item.id) {
      this.chat.isOpen = false;
      setTimeout(() => {
        this.activeConversation = null;
      }, 500);
      return;
    }

    this.disableForm = false;
    this.chat.isOpen = true;
    this.storeEditorContentInCache(this.activeConversation);

    this.activeConversation = item.conversation;
    this.existingContacts = [];
    this.restoreEditorContentFromCache(this.activeConversation);

    if (markAsRead) {
      await this.markMessagesAsRead(this.activeConversation);
    }

    this.reloadConversation(this.activeConversation?.id as number);
  }

  onExpandChange(expand: boolean): void {
    this.expand = expand;
  }

  onUpdateChat(): void {
    this.reloadConversation(this.activeConversation?.id as number);
  }

  onToggleSearch(): void {
    if (!this.searching) {
      this.searching = true;

      this.tab = 'tab-all';
    } else {
      this.searching = false;
      this.searchKeyword = '';
    }
  }

  onToggleFilter(): void {
    if (!this.filtering) {
      this.filtering = true;

      this.tab = 'tab-queue';
    } else {
      this.filtering = false;
    }
  }

  onOpenOverview(): void {
    this.routerService.navigate({ name: 'CrmChatOverview' });
  }

  async onRefresh(): Promise<void> {
    this.chat.isOpen = false;

    this.loading = true;
    try {
      this.onUpdateChat();

      await this.loadConversations();
      if (!this.searching) {
        this.loadAllChatConversations();
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      this.loading = false;
    }
  }

  async markMessagesAsRead(conversation: ConversationModel): Promise<void> {
    try {
      const chatItem = this.chatList.find((x) => x.conversation.id === conversation.id);
      if (chatItem) {
        const unreadMessages = chatItem.conversation.messages.filter((item) => {
          const incoming = item.origin.id === ConversationMessageOriginEnum.Incoming;
          const received = item.status.code === ConversationMessageStatusEnum.Received;
          return incoming && received;
        });
        const unreadIds = unreadMessages.map((x) => x.id);

        if (unreadIds.length) {
          await this.conversationService.markConversationMessagesAsRead(conversation.id, unreadIds);
        }

        unreadMessages.forEach((item) => {
          const obj = item;
          obj.status.code = ConversationMessageStatusEnum.Read;
          return obj;
        });
        chatItem.unreadCounter = CrmChat.getTotalIncomingUnreadMessage(chatItem.conversation.messages);
      }
    } catch (err) {
      // It is expected do not show error to user
      console.error(err && (err as Error).message);
    }
  }

  async sendOptin(): Promise<void> {
    if (!this.activeConversation) {
      return;
    }

    if (!this.activeConversation?.contato) {
      this.$notify.error(this.$t('crm.chat.messages.optinCantBeSent'), 5000);
      return;
    }

    const isProspect = this.activeConversation.tipo === ConversationTypeEnum.Prospect;

    let codProspect = '';
    if (isProspect && this.activeConversation.prospect) {
      codProspect = this.activeConversation.prospect
        ? this.activeConversation.prospect.codProspect : this.activeConversation.cliente.codCliente;
    }

    const clientId = isProspect ? codProspect : this.activeConversation.cnpj;
    const validacaoOptin = await this.conversationService
      .checkIfOptinAlreadySent(this.activeConversation.numeroWhatsapp, clientId);

    if (validacaoOptin.alreadyAnswered && validacaoOptin.needConfirmationToSend) {
      this.showDialogConfirmationOptin = true;
    } else {
      await this.validarIniciarConversaParaOptin();

      const response = await this.conversationService.sendOptin(this.activeConversation);
      if (!response.sent && response.needConfirmationToSend && response.alreadyAnswered) {
        this.showDialogConfirmationOptin = true;
      } else if (response.sent) {
        this.$notify.success(this.$t('crm.chat.messages.optinSuscessfullySent'), 5000);
        await this.reloadConversation(this.activeConversation.id);
      }
    }
  }

  private async validarIniciarConversaParaOptin(): Promise<void> {
    if (!this.activeConversation) {
      return;
    }

    if (this.activeConversation.isNew) {
      const conversation = await this.conversationService
        .createConversation(this.activeConversation, null, null, true);

      this.activeConversation.id = conversation.id;
    } else if (!this.activeConversation.atendente || !this.activeConversation.atendente.id) {
      // If conversation is not started, open before send first message
      await this.openConversation(this.activeConversation, this.userContactInfo.id, true);
    }
  }

  async optinDialogChoice(acceptChoice: boolean): Promise<void> {
    if (!this.activeConversation) {
      return;
    }

    if (acceptChoice) {
      await this.validarIniciarConversaParaOptin();
      const response = await this.conversationService.sendOptin(this.activeConversation, true);
      if (response.sent) {
        this.$notify.success(this.$t('crm.chat.messages.conversationSuscessfullyCreated'), 5000);
        await this.reloadConversation(this.activeConversation.id);
      }
    } else {
      await this.reloadConversation(this.activeConversation.id);
    }
  }

  openBlockNumberDialog(): void {
    this.dialogConfig.blockNumber.show = true;
  }

  openSelectTemplateDialog(): void {
    this.dialogConfig.selectTemplate.show = true;
  }

  openSelectQuickAnswerDialog(): void {
    this.dialogConfig.selectQuickAnswer.show = true;
  }

  openTransferConversationDialog(): void {
    this.dialogConfig.selectAttendant.show = true;
  }

  // eslint-disable-next-line class-methods-use-this
  gerarUuid(): string {
    return uuid();
  }

  onBlockNumberDialogClose(): void {
    this.dialogConfig.blockNumber.show = false;
  }

  onSelectTemplateDialogClose(): void {
    this.dialogConfig.selectTemplate.show = false;
  }

  onSelectQuickAnswerClose(): void {
    this.dialogConfig.selectQuickAnswer.show = false;
  }

  onPhotoAndVideoSelected(files: File[]): void {
    this.photoAndVideo = [];

    if (!files || !files.length) {
      return;
    }

    const allowedTypesAndSizes = {
      'image/jpg': 4718592, // 4.5 MB
      'image/jpeg': 4718592, // 4.5 MB
      'image/png': 4718592, // 4.5 MB
      'video/mp4': 16252928, // 15.5 MB
      'video/3gpp': 16252928, // 15.5 MB
    };

    try {
      this.validateMedia(files, allowedTypesAndSizes);

      this.dialogConfig.mediaPreview.type = 'photoAndVideo';
      this.dialogConfig.mediaPreview.files = files;
      this.dialogConfig.mediaPreview.show = true;
    } catch (err) {
      this.$notify.error(err && (err as Error).message, 5000);
    }
  }

  onDocumentSelected(files: File[]): void {
    this.document = [];

    if (!files || !files.length) {
      return;
    }

    const allowedTypesAndSizes = {
      '*': 67108864, // 64 MB
    };

    try {
      this.validateMedia(files, allowedTypesAndSizes);

      this.dialogConfig.mediaPreview.type = 'document';
      this.dialogConfig.mediaPreview.files = files;
      this.dialogConfig.mediaPreview.show = true;
    } catch (err) {
      this.$notify.error(err && (err as Error).message, 5000);
    }
  }

  async onSendMedia(items: IChatSendMediaItem[]): Promise<void> {
    if (!this.activeConversation) {
      return;
    }

    // If conversation is not started, open it before sends first message
    if (!this.activeConversation.atendente || !this.activeConversation.atendente.id) {
      await this.openConversation(this.activeConversation, this.userContactInfo.id);
    }

    const chatListItem = this.chatList.find((x) => x.conversation?.id === this.activeConversation?.id);

    items.forEach((item, index) => {
      const tempMessage = CrmChat.createMessageInstance(
        new Date().getTime() - 30 * index,
        item.caption || '',
        ConversationMessageOriginEnum.Outgoing,
        item.type,
        ConversationMessageStatusEnum.Temporary,
      );

      let name = '';

      if (item.file instanceof File) {
        name = item.file.name;
      }

      tempMessage.content = {
        data: {
          temporary: {
            type: item.file.type,
            name,
            dataUri: item.dataUri,
            filename: item.filename || '',
            caption: item.caption || '',
          },
        },
      };

      if (chatListItem) {
        chatListItem.conversation.messages.push(tempMessage);
      }

      this.sendMediaQueue.push(this.sendMediaMessage(item, tempMessage, this.activeConversation));
    });
  }

  sendMediaQueue: Promise<ConversationMessageModel | null>[] = [];

  async sendMediaMessage(
    media: IChatSendMediaItem,
    tempMessage: ConversationMessageModel,
    conversation: ConversationModel | null,
  ): Promise<ConversationMessageModel | null> {
    if (!media || !conversation) {
      return null;
    }

    try {
      const mediaId = await this.processMedia(media);

      const message = await this.conversationService
        .sendMediaMessage(conversation, media.type, mediaId, media.caption || '', media.filename || '')
        .then((result) => {
          const data = result;
          data.content.data.temporary = {
            dataUri: media.dataUri,
          };

          const chatListItem = this.chatList.find((x) => x.conversation?.id === conversation.id);
          if (chatListItem && chatListItem.conversation.id === conversation.id) {
            const tempIndex = chatListItem.conversation.messages.findIndex((x) => x.id === tempMessage.id);
            if (tempIndex > -1) {
              chatListItem.conversation.messages.splice(tempIndex, 1, data);
            }
          }

          return data;
        });

      return message;
    } catch (err) {
      const message = tempMessage;
      message.status.code = ConversationMessageStatusEnum.TemporaryInError;

      if (err) {
        try {
          const parsedError = JSON.parse(JSON.parse((err as Error).message));

          if (parsedError?.errors && parsedError?.errors[0]?.code) {
            const errorCode = parsedError.errors[0].code;
            message.errorCode = errorCode;
            this.$notify.error(this.$t(`crm.chat.messages.error${errorCode}`), 5000);
          } else {
            this.$notify.error((err as Error).message, 5000);
          }
        } catch (err2) {
          this.$notify.error(err && (err as Error).message, 5000);
        }
      }
    }

    return null;
  }

  async processMedia(media: IChatSendMediaItem): Promise<string> {
    const credentials = await this.conversationService.getCachedCommServiceToken();
    let mediaId: string | null = null;
    let checksum: string | null = null;

    try {
      checksum = await FileHelper.getChecksum(media.file);
    } catch (err) {
      console.error(`Error on retrieve file MD5 checksum: ${(err as Error).message}`);
    }

    let alreadyUploaded: ConversationMessageMediaModel | null = null;

    try {
      alreadyUploaded = checksum
        ? await this.conversationService.getMediaInfoFromCommService(credentials, checksum)
        : null;
    } catch {
      // Do nothing
    }

    if (alreadyUploaded) {
      mediaId = alreadyUploaded.mediaId;
    } else {
      const upload = await this.conversationService.uploadMediaToCommService(credentials, media.type, media.file);

      mediaId = upload.mediaId;
    }

    return mediaId;
  }

  onMediaPreviewClose(): void {
    this.dialogConfig.mediaPreview.show = false;
    this.dialogConfig.mediaPreview.files = null;
    this.dialogConfig.mediaPreview.type = null;

    this.photoAndVideo = [];
  }

  openEmoticonDialog(): void {
    this.dialogConfig.selectEmoticon.show = true;
  }

  onSelectEmoticonDialogClose(): void {
    this.dialogConfig.selectEmoticon.show = false;
  }

  onSelectEmoticon(value: string): void {
    if (!value) {
      return;
    }

    let parsedContent = '';
    let shiftEnter = false;
    if (this.editorContent) {
      shiftEnter = this.editorContent.includes('<br>');
      const regexp = new RegExp(/<p>(.+)<\/p>/g);
      const execResult = regexp.exec(this.editorContent);
      if (execResult && execResult[1]) {
        parsedContent = execResult[1] as string;
      }
    }

    if (this.lastEditorKeydownPosition) {
      const pos = this.lastEditorKeydownPosition;
      if (shiftEnter) {
        const currentTextSelected = this.currentSelection?.anchorNode?.data;
        const splitted = parsedContent.split('<br>');

        const index = splitted.findIndex((row) => row === currentTextSelected);

        splitted[index] = `${splitted[index].substr(0, pos)}${value}${splitted[index].substr(pos)}`;
        const newContent = splitted.join('<br>');
        this.editorContent = `<p>${newContent}</p>`;
      } else {
        const newContent = `${parsedContent.substr(0, pos)}${value}${parsedContent.substr(pos)}`;
        this.editorContent = `<p>${newContent}</p>`;
      }
    } else {
      this.editorContent = `<p>${value}</p>`;
    }
  }

  async onSelectAttendant(data: ITransferData): Promise<void> {
    if (this.dialogConfig.dynamicUraMode.show) {
      await this.conversationService.changeDepartment(
        this.activeConversation as ConversationModel,
        data.department as ConversationDepartmentModel,
      );
      this.chat.isOpen = false;
    }

    if (data.attendant && this.activeConversation) {
      if (this.activeConversation?.atendente?.id && data.attendant.id === this.activeConversation.atendente.id) {
        this.$notify.error(this.$t('crm.chat.messages.notAllowedTransferToSameAttendant'));
        return;
      }

      if (!this.activeConversation.isOpen) {
        try {
          await this.transferConversation(this.activeConversation, data.attendant.id);
          if (data.attendant.id !== this.userContactInfo.id) {
            this.chat.isOpen = false;
          }
        } catch (err) {
          this.$notify.error(err && (err as Error).message, 5000);
        } finally {
          this.loadingDialog = false;
        }
      } else if (!this.activeConversation.contato?.id) {
        this.dialogConfig.closeAction.conversation = this.activeConversation;
        this.dialogConfig.closeAction.transfer = true;
        this.dialogConfig.closeAction.newAttendant = data.attendant.id;
        this.dialogConfig.closeAction.show = true;
      } else {
        this.dialogConfig.closeConversation.transfer = true;
        this.dialogConfig.closeConversation.newAttendant = data.attendant.id;
        this.dialogConfig.closeConversation.mistake = false;
        this.dialogConfig.closeConversation.justified = false;
        this.dialogConfig.closeConversation.conversation = [this.activeConversation];
        this.dialogConfig.closeConversation.show = true;
      }
    }
    await this.loadConversations();
  }

  onCancelOperation(): void {
    this.cancelOperation = false;
  }

  onCloseSelectAttendantDialogClose(): void {
    this.dialogConfig.selectAttendant.show = false;
  }

  async onCloseOpenConversationDialogClose(): Promise<void> {
    this.dialogConfig.openConversationAndSelectDepartment.show = false;
    if (this.cancelOperation) {
      this.activeConversation = null;
    }
    this.cancelOperation = true;
  }

  onAfterSaveContact(conversation: ConversationModel, closeConversation: boolean, newAttendant?: number): void {
    this.dialogConfig.contact.hasExistingContacts = false;
    this.chatList.map((item) => {
      const obj = item;
      if (obj.conversation.id === conversation.id) {
        obj.conversation = conversation;
      }
      return obj;
    });
    this.chatList = [...this.chatList];

    if (closeConversation && conversation && !!conversation.contato?.id) {
      this.dialogConfig.closeConversation.conversation = [conversation];
      this.dialogConfig.closeConversation.transfer = !!newAttendant;
      this.dialogConfig.closeConversation.newAttendant = newAttendant;
      this.dialogConfig.closeConversation.show = true;
    }

    this.refreshLocatedContacts = uuid();
  }

  onContactDialogClose(): void {
    this.dialogConfig.contact.show = false;
    this.dialogConfig.contact.closeConversationOnSave = false;
    this.dialogConfig.contact.transfer = false;
  }

  onOpenContact(conversation: ConversationModel): void {
    this.dialogConfig.contact.conversation = conversation;
    this.dialogConfig.contact.closeConversationOnSave = false;
    this.dialogConfig.contact.transfer = false;
    this.dialogConfig.contact.show = true;
  }

  onDeleteMessage(message: ConversationMessageModel): void {
    if (this.activeConversation) {
      const chatListItem = this.chatList.find((x) => x.conversation?.id === this.activeConversation?.id);
      if (chatListItem) {
        const tempIndex = chatListItem.conversation.messages.findIndex((x) => x.id === message.id);
        if (tempIndex > -1) {
          chatListItem.conversation.messages.splice(tempIndex, 1);
        }
      }
    }
  }

  async onBlockNumber(reason: string): Promise<void> {
    const loader = this.$loading.show();
    const model = new WhatsappBlockModel();
    if (this.activeConversation && this.activeConversation.contato) {
      model.idContato = this.activeConversation.contato.id;
    }
    try {
      if (this.activeConversation?.numeroContato) {
        model.numeroWhatsapp = this.activeConversation?.numeroContato;
        model.motivo = reason;
        if (model.numeroWhatsapp) {
          await this.contactService.blockNumber(model);
          this.blockedNumbers.push(model.numeroWhatsapp);
          this.$notify.success(
            this.$t('crm.chat.messages.blockNumberSuccess', { contactNumber: model.numeroWhatsapp }),
          );
        }
      }
    } catch (err) {
      this.$notify.error(this.$t('crm.chat.messages.blockNumberError', { contactNumber: model.numeroWhatsapp }));
    } finally {
      loader.hide();
    }
  }

  async onSelectTemplate(template: ConversationTemplateModel): Promise<void> {
    if (template && this.activeConversation) {
      this.loadingDialog = true;

      try {
        const variableValues = this.setVariableValues(template);
        if (this.activeConversation.isNew) {
          let conversation = new ConversationModel();
          conversation = await this.conversationService.createConversation(
            this.activeConversation,
            template,
            variableValues,
            null,
          );
          this.activeConversation.id = conversation.id;
          this.$notify.success(this.$t('crm.chat.messages.conversationSuscessfullyCreated'), 5000);
          await this.reloadConversation(this.activeConversation.id);
        } else {
          // If conversation is not started, open before send first message
          if (!this.activeConversation.atendente || !this.activeConversation.atendente.id) {
            await this.openConversation(this.activeConversation, this.userContactInfo.id);
          }
          await this.conversationService.sendTemplateMessage(this.activeConversation, template, true, variableValues);
        }
      } catch (err) {
        this.$notify.error(err && (err as Error).message, 5000);
      } finally {
        this.loadingDialog = false;
      }
    }
  }

  async onSelectQuickAnswer(quickAnswer: ConversationQuickAnswerModel): Promise<void> {
    if (this.activeConversation) {
      try {
        this.loadingDialog = true;
        if (!this.activeConversation.atendente || !this.activeConversation.atendente.id) {
          await this.openConversation(this.activeConversation, this.userContactInfo.id);
        }
        await this.conversationService.sendTextMessage(this.activeConversation, quickAnswer.respostaPronta, true);
      } catch (err) {
        this.$notify.error(err && (err as Error).message, 5000);
      } finally {
        this.loadingDialog = false;
      }
    }
  }

  async openCloseConversationDialog(model: ConversationModel, setClient = false): Promise<void> {
    const conversation = model;

    if (!conversation) {
      this.$notify.warn('crm.chat.messages.thereIsNoActiveChatConversationToBeClosed');
      return;
    }

    // Ensure exists contact to client before close conversation
    const isProspect = conversation.tipo === ConversationTypeEnum.Prospect;

    let codProspect = '';
    if (isProspect && conversation.prospect) {
      codProspect = conversation.prospect ? conversation.prospect.codProspect : conversation.cliente.codCliente;
    }

    const clientId = isProspect ? codProspect : conversation.cnpj;

    if (!conversation.contato?.id && clientId) {
      this.loadingDialog = true;
      try {
        const contact = await this.contactService.getContactByClientId(
          conversation.numeroWhatsapp,
          clientId,
          isProspect ? ClientTypeEnum.Prospect : ClientTypeEnum.Client,
          this.settings?.flagPermiteClientesSituacao99Crm360,
        );

        if (contact) {
          conversation.contato = contact;
        }
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.loadingDialog = false;
      }
    } else if (!setClient && !conversation.cnpj && !conversation.prospect) {
      this.loadingDialog = true;
      try {
        this.existingContacts = await this.contactService.verifyWaIdContacts(
          null,
          conversation.numeroWhatsapp,
          null,
          null,
          false,
        );

        const clientContacts: ContactModel[] = await this.contactService.verifyWaIdContactsClient(
          undefined,
          conversation.numeroWhatsapp,
          false,
        );
        clientContacts.forEach((contact) => {
          if (this.existingContacts.find((c) => c.cnpj === contact.cnpj)) return;
          this.existingContacts.push(contact);
        });

        const prospectContacts: ContactModel[] = await this.contactService.verifyWaIdContactsProspect(
          undefined,
          conversation.numeroWhatsapp,
          false,
        );
        prospectContacts.forEach((contact) => {
          if (this.existingContacts.find((c) => c.idProspect === contact.idProspect)) return;
          this.existingContacts.push(contact);
        });
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.loadingDialog = false;
      }
    }

    if (this.existingContacts && this.existingContacts.length > 0) {
      this.dialogConfig.selectClientProspect.show = true;
    } else if (!conversation.contato?.id) {
      this.dialogConfig.closeAction.conversation = conversation;
      this.dialogConfig.closeAction.show = true;
    } else {
      this.dialogConfig.closeConversation.mistake = false;
      this.dialogConfig.closeConversation.justified = false;
      this.dialogConfig.closeConversation.transfer = false;
      this.dialogConfig.closeConversation.conversation = [conversation];
      this.dialogConfig.closeConversation.show = true;
    }
  }

  onCloseConversationDialogOpen(): void {
    this.dialogConfig.closeConversation.show = true;
  }

  onCloseConversationDialogClose(): void {
    this.dialogConfig.closeConversation.show = false;
    this.dialogConfig.closeConversation.transfer = false;
    this.dialogConfig.closeConversation.newAttendant = null;
    this.dialogConfig.closeConversation.mistake = false;
    this.dialogConfig.closeConversation.justified = false;
    this.closeChatmodel = {
      description: '',
      origin: null,
      historyType: null,
      consolidateSale: false,
      contact: null,
      groupArea: null,
      mistake: false,
      justified: false,
    };
  }

  onSelectClientProspectDialogClose(): void {
    this.existingContacts = [];
    this.dialogConfig.selectClientProspect.show = false;
  }

  onAfterCloseConversation(conversation: ConversationModel): void {
    this.chatList = this.chatList.filter((item) => item.conversation.id !== conversation.id);
    this.chat.isOpen = false;

    this.dialogConfig.closeAction.transfer = false;
    this.dialogConfig.closeAction.newAttendant = null;
  }

  saveContactAndCloseConversation(conversation: ConversationModel, newAttendant?: number): void {
    this.dialogConfig.contact.conversation = conversation;
    this.dialogConfig.contact.closeConversationOnSave = true;
    this.dialogConfig.contact.transfer = !!newAttendant;
    this.dialogConfig.contact.newAttendant = newAttendant;
    this.dialogConfig.contact.show = true;
  }

  closeAsMistake(conversation: ConversationModel, newAttendant?: number): void {
    this.dialogConfig.closeConversation.conversation = [conversation];
    this.dialogConfig.closeConversation.mistake = true;
    this.dialogConfig.closeConversation.transfer = !!newAttendant;
    this.dialogConfig.closeConversation.newAttendant = newAttendant;
    this.dialogConfig.closeConversation.show = true;
  }

  closeAsJustified(conversation: ConversationModel, newAttendant?: number): void {
    this.dialogConfig.closeConversation.conversation = [conversation];
    this.dialogConfig.closeConversation.justified = true;
    this.dialogConfig.closeConversation.transfer = !!newAttendant;
    this.dialogConfig.closeConversation.newAttendant = newAttendant;
    this.dialogConfig.closeConversation.show = true;
  }

  openHistoryConversationDialog(): void {
    if (!this.activeConversation) {
      return;
    }

    this.dialogConfig.history.show = true;
    this.dialogConfig.history.conversation = this.activeConversation;
  }

  onCloseHistoryDialogClose(): void {
    this.dialogConfig.history.show = false;
    this.dialogConfig.history.conversation = false;
  }

  subscribeToActions(): void {
    if (!this.sockets) {
      return;
    }

    // Ensure unsuscribe already existent inscriptions to avoid duplication
    this.unsubscribeActions();

    this.$socket.emit('ENTRAR_SALA', this.getSalaAtendente());

    this.sockets.subscribe('MESSAGE_UPDATED', async (data: string) => {
      const parsedData = JSON.parse(data);
      const conversation = plainToClass(ConversationModel, JSON.parse(parsedData.conversation));
      const message = plainToClass(ConversationMessageModel, JSON.parse(parsedData.message));

      const chatListItem = this.chatList.find((x) => x.conversation?.id === conversation.id);

      if (chatListItem) {
        CrmChat.populateConversationModel(chatListItem.conversation, conversation);

        const messageIndex = chatListItem.conversation.messages.findIndex((x) => x.messageId === message.messageId);
        if (messageIndex < 0) {
          chatListItem.conversation.messages.push(message);
        } else {
          CrmChat.populateConversationMessageModel(chatListItem.conversation.messages[messageIndex], message);
        }

        chatListItem.unreadCounter = CrmChat.getTotalIncomingUnreadMessage(chatListItem.conversation.messages);

        const isActiveConversation = chatListItem.conversation.id === this.activeConversation?.id;
        const isActiveUserConversation = this.activeConversation?.atendente?.id === this.userContactInfo.id;
        if (this.activeConversation && isActiveConversation && isActiveUserConversation) {
          this.activeConversation.messages = chatListItem.conversation.messages;
          await this.markMessagesAsRead(this.activeConversation);
        }
      } else {
        this.loadConversations(conversation);
      }
    });

    this.sockets.subscribe('CONVERSATION_UPDATED', (data: string) => {
      const parsedData = JSON.parse(data);
      const conversation = plainToClass(ConversationModel, JSON.parse(parsedData.conversation));
      this.updateConversationInChatLists(conversation);
    });

    this.sockets.subscribe('CONVERSATION_CREATED', (data: string) => {
      const parsedData = JSON.parse(data);
      const conversation = plainToClass(ConversationModel, JSON.parse(parsedData.conversation));
      this.loadConversations(conversation);
    });

    this.sockets.subscribe('CONVERSATION_TRANSFERED', (data: string) => {
      const parsedData = JSON.parse(data);
      const conversation = plainToClass(ConversationModel, JSON.parse(parsedData.conversation));
      this.loadConversations(conversation);
      const newConversation = plainToClass(ConversationModel, JSON.parse(parsedData.newConversation));
      this.loadConversations(newConversation);
    });

    this.sockets.subscribe('CONVERSATION_OPEN', async (data: string) => {
      const parsedData = JSON.parse(data);
      const conversation = plainToClass(ConversationModel, JSON.parse(parsedData.conversation));
      this.updateConversationInChatLists(conversation);
    });

    this.sockets.subscribe('CONVERSATION_CLOSED', (data: string) => {
      const parsedData = JSON.parse(data);
      const conversation = plainToClass(ConversationModel, JSON.parse(parsedData.conversation));
      this.loadConversations(conversation);

      if (this.activeConversation && this.activeConversation.id === conversation.id) {
        this.chat.isOpen = false;
      }
    });

    this.sockets.subscribe('SATISFACTION_SURVEY', (data: string) => {
      const parsedData = JSON.parse(data);
      const conversation = plainToClass(ConversationModel, JSON.parse(parsedData.conversation));
      this.loadConversations(conversation);

      if (this.activeConversation && this.activeConversation.id === conversation.id) {
        this.chat.isOpen = false;
      }
    });
  }

  getSalaAtendente(): string {
    const idAtendente = this.userContactInfo.id;
    return `atendente_${idAtendente}`;
  }

  unsubscribeActions(): void {
    if (!this.sockets) {
      return;
    }

    this.$socket.emit('SAIR_SALA', this.getSalaAtendente());

    this.sockets.unsubscribe('MESSAGE_UPDATED');
    this.sockets.unsubscribe('CONVERSATION_UPDATED');
    this.sockets.unsubscribe('CONVERSATION_CREATED');
    this.sockets.unsubscribe('CONVERSATION_TRANSFERED');
    this.sockets.unsubscribe('CONVERSATION_OPEN');
    this.sockets.unsubscribe('CONVERSATION_CLOSED');
  }

  editorMessages(): IEditorMessage[] {
    if (this.activeConversation && this.isNumberBlocked) {
      return [{ message: this.$t('crm.chat.messages.blockedNumber').toString() }];
    }

    if (this.isNewConversation) {
      return [
        {
          message: this.$t('crm.chat.messages.selectTemplateToStartConversation').toString(),
          action: this.openSelectTemplateDialog,
        },
      ];
    }

    if (this.activeConversation?.isOpen && this.activeConversation?.atendente.id !== this.userContactInfo.id) {
      return [
        {
          message: this.$t('crm.chat.messages.conversationAttendingBy', {
            user: this.activeConversation?.atendente?.nome,
          }).toString(),
        },
      ];
    }

    if (this.isRecordingAudio) {
      return [{ message: this.$t('crm.chat.messages.recordingAudio').toString() }];
    }

    if (this.openingConversation) {
      return [{ message: this.$t('crm.chat.messages.conversationOpening').toString() }];
    }

    if (this.transferingConversation) {
      return [{ message: this.$t('crm.chat.messages.conversationTransfering').toString() }];
    }

    if (this.activeConversation?.bloqueado) {
      return [
        {
          message: this.$t('crm.chat.messages.conversationBlocked').toString(),
          action: this.openSelectTemplateDialog,
        },
      ];
    }

    if (this.activeConversation?.expirado) {
      let message = this.$t('crm.chat.messages.conversationExpired').toString();

      const lastMessage = last(this.activeConversation.messages);
      if (lastMessage) {
        const isOutgoing = lastMessage.origin.id === ConversationMessageOriginEnum.Outgoing;
        const isTemplate = lastMessage.type.code === ConversationMessageTypeEnum.Template;

        if (isOutgoing && isTemplate) {
          message = this.$t('crm.chat.messages.conversationExpiredWithSentTemplate').toString();
        }
      }

      return [
        {
          message,
          action: this.openSelectTemplateDialog,
        },
      ];
    }

    return [];
  }

  setVariableValues(template: ConversationTemplateModel) {
    const templateAux = template;
    const variablesAux: Map<string, string> = new Map<string, string>();
    if (this.activeConversation && templateAux.variables) {
      templateAux.variables.forEach((variable) => {
        let contactName: string | null = null;
        if (this.activeConversation) {
          if (variable.nome === 'contact_name') {
            if (this.activeConversation.contato && this.activeConversation.contato.nome) {
              contactName = this.activeConversation.contato.nome;
            } else if (this.activeConversation.prospect && this.activeConversation.prospect.razaoSocial) {
              contactName = this.activeConversation.prospect.razaoSocial;
            } else if (this.activeConversation.cliente && this.activeConversation.cliente.nome) {
              contactName = this.activeConversation.cliente.nome;
            }
            if (contactName) {
              variablesAux.set(variable.nome, contactName);
            }
          } else if (variable.nome === 'attendant_name') {
            let attendantName: string | null = null;
            if (this.activeConversation.atendente && this.activeConversation.atendente.nome) {
              attendantName = this.activeConversation.atendente.nome;
            } else {
              attendantName = this.userContactInfo.nome;
            }
            if (attendantName) {
              variablesAux.set(variable.nome, attendantName);
            }
          }
        }
      });
    }
    return variablesAux;
  }

  showCloseConversationDialogAction(): boolean {
    return !!this.activeConversation?.waConversationId;
  }

  showBlockNumberAction(): boolean {
    if (this.isNumberBlocked) return false;
    return !this.activeConversation?.bloqueado && !this.activeConversation?.expirado;
  }

  showSendOptin(): boolean {
    const isOpen = this.activeConversation?.protocolo !== null && this.activeConversation?.protocolo !== undefined;
    if (!isOpen) return false;

    const isRelatedWithCurrentAttendant = this.activeConversation?.atendente?.id === this.userContactInfo.id;
    const realizaEnvioCampanhas = (this.settings?.realizaEnvioCampanhasInformativos || false);

    return !this.isNumberBlocked && isOpen && isRelatedWithCurrentAttendant && realizaEnvioCampanhas;
  }

  showSelectTemplateDialogAction(): boolean {
    const isOpen = this.activeConversation?.isOpen;
    const isRelatedWithCurrentAttendant = this.activeConversation?.atendente?.id === this.userContactInfo.id;

    return !this.isNumberBlocked && (!isOpen || (isOpen && isRelatedWithCurrentAttendant));
  }

  showTransferConversationAction(): boolean {
    return !this.isNumberBlocked && !this.activeConversation?.isNew;
  }

  showQuickAnswerConversationAction(): boolean {
    return !this.isNumberBlocked && !this.activeConversation?.isNew && !this.activeConversation?.bloqueado;
  }

  showOpenContactDialogAction(): boolean {
    return !this.activeConversation?.isNew;
  }

  showDialogActions(): boolean {
    const selectTemplateDialog = this.showSelectTemplateDialogAction();
    const openContactDialog = this.showOpenContactDialogAction();
    const closeConversationDialog = this.showCloseConversationDialogAction();
    const transferConversation = this.showTransferConversationAction();
    return selectTemplateDialog || openContactDialog || closeConversationDialog || transferConversation;
  }

  onEditorEvent({ view }: { view: EditorView }): void {
    this.currentSelection = view.domObserver?.currentSelection;
    this.lastEditorKeydownPosition = view.domObserver?.currentSelection.focusOffset;
  }

  onDragOver() {
    this.chat.isUploadingFile = true;
  }

  onToggleOpenUppy() {
    this.chat.isUploadingFile = !this.chat.isUploadingFile;
  }

  onStartCaptureAudio(): void {
    this.isRecordingAudio = true;
  }

  onFinishCaptureAudio(): void {
    this.isRecordingAudio = false;
  }

  async onReceiveAudio(event: Blob): Promise<void> {
    if (!this.activeConversation) {
      return;
    }

    const media: IChatSendMediaItem = {
      type: ConversationMessageTypeEnum.Audio,
      file: event,
      dataUri: window.URL.createObjectURL(event),
    };

    const tempMessage = CrmChat.createMessageInstance(
      new Date().getTime(),
      '',
      ConversationMessageOriginEnum.Outgoing,
      media.type,
      ConversationMessageStatusEnum.Temporary,
    );
    tempMessage.content = {
      data: {
        temporary: {
          type: media.type,
          name: '',
          dataUri: media.dataUri,
        },
      },
    };

    const chatListItem = this.chatList.find((x) => x.conversation?.id === this.activeConversation?.id);
    if (chatListItem) {
      chatListItem.conversation.messages.push(tempMessage);
    }

    await this.sendMediaMessage(media, tempMessage, this.activeConversation);
  }

  get notNormalUser(): boolean {
    return this.settings?.loggedUserType !== UserTypeEnum.Normal;
  }

  get userChatList(): IChatListItem[] {
    let list = this.chatList.filter(
      (x) => x.conversation.atendente && x.conversation.atendente.id === this.userContactInfo.id,
    );
    list = this.onFilterChanged(list);

    return orderBy(list, ['conversation.dataUltimaMensagem'], ['desc']);
  }

  get onHoldChatList(): IChatListItem[] {
    let list = this.chatList.filter(
      (x) => (!x.conversation.atendente || !x.conversation.atendente.id) && !x.conversation.dataInicio,
    );
    list = this.onFilterChanged(list);

    return orderBy(list, ['conversation.dataUltimaMensagem'], ['asc']);
  }

  get allChatList(): IChatListItem[] {
    let list = this.chatList;
    if (this.searchKeyword && this.searchKeyword.length > 1) {
      list = this.chatList.filter((item) => {
        const parsedNumberKeyword = this.searchKeyword.replace(/\D/g, '');

        if (parsedNumberKeyword.length > 1) {
          const parsedCnpj = (item.conversation.cnpj || '').replace(/\D/g, '');
          if (parsedCnpj.includes(parsedNumberKeyword)) {
            return true;
          }
          const parsedWhatsappNumber = item.conversation.numeroWhatsapp.replace(/\D/g, '');
          if (parsedWhatsappNumber.includes(parsedNumberKeyword)) {
            return true;
          }
        }

        const parsedKeyword = toLower(this.searchKeyword);

        if (item.conversation.contato?.nome) {
          const parsedContactName = toLower(item.conversation.contato.nome);
          if (parsedContactName.includes(parsedKeyword)) {
            return true;
          }
        }

        if (item.conversation.nomeContato) {
          const parsedWaContactName = toLower(item.conversation.nomeContato);
          if (parsedWaContactName.includes(parsedKeyword)) {
            return true;
          }
        }

        let parsedCompanyName = '';

        if (item.conversation?.tipo === ConversationTypeEnum.Prospect && item.conversation.prospect) {
          parsedCompanyName = toLower(item.conversation.prospect?.fantasia || item.conversation.prospect?.razaoSocial);
        }

        parsedCompanyName = toLower(item.conversation?.cliente?.nome || '');

        if (parsedCompanyName.includes(parsedKeyword)) {
          return true;
        }

        parsedCompanyName = toLower(item.conversation?.cliente?.nomeFantasia || '');

        if (parsedCompanyName.includes(parsedKeyword)) {
          return true;
        }

        return false;
      });
    }

    list = this.onFilterChanged(list);

    return list.sort((a, b) => {
      let dataA!: Date;
      let dataB!: Date;

      if (a.conversation.dataUltimaMensagem) {
        dataA = new Date(a.conversation.dataUltimaMensagem);
      } else {
        dataA = new Date(a.conversation.dataInicio);
      }
      if (b.conversation.dataUltimaMensagem) {
        dataB = new Date(b.conversation.dataUltimaMensagem);
      } else {
        dataB = new Date(b.conversation.dataInicio);
      }
      return +dataB - +dataA;
    });
  }

  get displayedChatList() {
    if (
      (this.searchKeyword && this.searchKeyword.length > 1)
      || this.filters.contactTypes !== null
      || this.filters.attendenceQueues !== null
    ) {
      return this.allChatList;
    }

    return this.incrementalChatList;
  }

  get isNumberBlocked(): boolean {
    return !!(
      (this.activeConversation
        && this.blockedNumbers.find(
          (item) => this.activeConversation?.numeroContato
            && item.replace(/[^0-9]/g, '').includes(this.activeConversation.numeroContato.replace(/[^0-9]/g, '')),
        ))
      || (this.activeConversation?.contato
        && this.blockedNumbers.find(
          (item) => this.activeConversation?.contato.whatsapp
            && item.replace(/[^0-9]/g, '').includes(this.activeConversation.contato.whatsapp.replace(/[^0-9]/g, '')),
        ))
    );
  }

  get isDialogBlocked(): boolean {
    if (!this.activeConversation || this.isNewConversation || this.isNumberBlocked) {
      return true;
    }

    const { expirado, fechado, bloqueado } = this.activeConversation;
    return !!expirado || !!fechado || !!bloqueado;
  }

  get isNewConversation(): boolean {
    if (this.activeConversation && this.activeConversation.isNew) {
      return true;
    }

    return false;
  }

  get isUnansweredConversation(): boolean {
    if (this.activeConversation) {
      const lastMessage = last(this.activeConversation?.messages);
      if (lastMessage?.type.code === ConversationMessageTypeEnum.Template) {
        return true;
      }
    }
    return false;
  }

  get showChatCounter(): boolean {
    return this.userChatList.length > 0;
  }

  get showOnHoldCounter(): boolean {
    return this.onHoldChatList.length > 0;
  }

  get mainChatCounter(): number {
    const unreadMessages = sumBy(this.userChatList, (x) => x.unreadCounter || 0);
    return unreadMessages;
  }

  get closeDialogTitle(): string {
    return this.dialogConfig.closeConversation.transfer
      ? this.$t('crm.chat.dialog.closeConversation.title.transfer').toString()
      : this.$t('crm.chat.dialog.closeConversation.title.close').toString();
  }

  get historyDialogTitle(): string {
    if (!this.dialogConfig.history.conversation) {
      return '';
    }

    const conversation = this.dialogConfig.history.conversation as ConversationModel;
    let contact = '';
    if (conversation.contato?.nome) {
      contact = conversation.contato.nome;
    } else {
      contact = conversation.numeroWhatsapp;
    }
    return this.$t('crm.chat.dialog.history.title', { contact }).toString();
  }

  get clickToPasteAttachments(): string {
    return this.$t('crm.chat.dialog.clickToPasteAttachments').toString();
  }

  get sendQuickAnswer(): string {
    return this.$t('crm.chat.dialog.sendQuickAnswer').toString();
  }

  get contactTypeOptions(): ISelectOption<string>[] {
    return [
      {
        id: 'C',
        code: ConversationTypeEnum.Client,
        description: this.$t('crm.chat.drawer.filter.contactType.client').toString(),
      },
      {
        id: 'P',
        code: ConversationTypeEnum.Prospect,
        description: this.$t('crm.chat.drawer.filter.contactType.prospect').toString(),
      },
      {
        id: 'SC',
        code: ConversationTypeEnum.Undefined,
        description: this.$t('crm.chat.drawer.filter.contactType.notRegistered').toString(),
      },
    ];
  }

  get attendenceQueueOptions(): ConversationDepartmentModel[] {
    return this.chatList.map((item) => item.conversation.departamento);
  }

  onFilterChanged(list: IChatListItem[]): IChatListItem[] {
    let listAux = list;
    if (this.filters.contactTypes && this.filters.contactTypes.length > 0) {
      listAux = listAux.filter((item) => {
        if (
          this.filters.contactTypes?.map((filter) => filter.code).includes(ConversationTypeEnum.Prospect)
          && (item.conversation.prospect
            || (item.conversation.cliente && item.conversation.cliente.tipo === ConversationTypeEnum.Prospect))
        ) {
          return true;
        }
        if (
          this.filters.contactTypes?.map((filter) => filter.code).includes(ConversationTypeEnum.Client)
          && item.conversation.cliente
          && (item.conversation.cliente.tipo === ConversationTypeEnum.Client
            || item.conversation.cliente.type === ClientTypeEnum.Client)
        ) {
          return true;
        }

        if (
          !item.conversation.cliente
          && !item.conversation.prospect
          && this.filters.contactTypes?.map((filter) => filter.code).includes(ConversationTypeEnum.Undefined)
        ) {
          return true;
        }
        return false;
      });
    }
    if (this.filters.attendenceQueues && this.filters.attendenceQueues.length > 0) {
      listAux = listAux.filter(
        (item) => item.conversation.departamento
          && this.filters.attendenceQueues
            ?.map((attendanceQueue) => attendanceQueue.id)
            .includes(item.conversation.departamento.id),
      );
    }
    return listAux || [];
  }

  private async openConversation(conversation: ConversationModel,
    attendantId: number, skipSendOptin = false): Promise<void> {
    this.openingConversation = true;

    try {
      const updatedConversation = await this.conversationService.openConversation(conversation, {
        id: attendantId,
      } as UserModel, skipSendOptin);

      this.updateConversationInChatLists(updatedConversation);

      await this.loadConversationLogs(conversation);
    } catch (err) {
      this.$notify.error(err && (err as Error).message, 5000);
      throw err;
    } finally {
      this.openingConversation = false;
    }
  }

  private async transferConversation(conversation: ConversationModel, attendantId: number): Promise<void> {
    this.transferingConversation = true;

    try {
      const updatedConversation = await this.conversationService.openConversation(conversation, {
        id: attendantId,
      } as UserModel);

      this.updateConversationInChatLists(updatedConversation);

      await this.loadConversationLogs(conversation);
    } catch (err) {
      this.$notify.error(err && (err as Error).message, 5000);
      throw err;
    } finally {
      this.transferingConversation = false;
    }
  }

  async onSetConversationClient(contact: ContactModel): Promise<void> {
    if (this.activeConversation && contact) {
      const conversa = cloneDeep(this.activeConversation);
      conversa.contato = contact;
      conversa.tipo = contact.tipoCliente === 'Cliente'
        ? ConversationTypeEnum.Client : ConversationTypeEnum.Prospect;
      if (contact.tipoCliente === 'Cliente') {
        conversa.cnpj = contact.cnpj;
        conversa.cliente = new ClientModel();
        conversa.cliente.cnpjCpf = contact.cnpj;
        if (contact.nomeCliente) conversa.cliente.nome = contact.nomeCliente;
      } else {
        conversa.prospect = new ProspectModel();
        if (contact.idProspect) conversa.prospect.codProspect = contact.idProspect.toString();
        if (contact.nomeCliente) conversa.prospect.razaoSocial = contact.nomeCliente;
      }

      this.activeConversation = cloneDeep(conversa);
      await this.conversationService.setClientProspectConversation(this.activeConversation,
        this.activeConversation.tipo === ConversationTypeEnum.Client ? null : contact.idProspect);

      this.loadConversations(this.activeConversation);
      this.reloadConversation(this.activeConversation.id);

      if (this.checkLocatedContactsAndOpenSelection !== '') {
        this.checkLocatedContactsAndOpenSelection = '';
        this.openTransferConversationDialog();
      }
    }
  }

  private updateConversationInChatLists(conversation: ConversationModel): void {
    const chatListItem = this.chatList.find((x) => x.conversation.id === conversation?.id);
    if (chatListItem) {
      CrmChat.populateConversationModel(chatListItem.conversation, conversation);

      this.chatList = [...this.chatList];
    } else {
      const item = {} as IChatListItem;
      CrmChat.populateChatListItem(item, conversation);
      this.chatList.push(item);
    }

    if (this.activeConversation && this.activeConversation.id === conversation.id) {
      CrmChat.populateConversationModel(this.activeConversation, conversation);
    }
  }

  private storeEditorContentInCache(conversation: ConversationModel | null): void {
    if (!conversation) {
      return;
    }

    this.cachedEditorContent.set(conversation.id, this.editorContent);
  }

  private restoreEditorContentFromCache(conversation: ConversationModel | null): void {
    if (!conversation) {
      return;
    }

    this.editorContent = this.isDialogBlocked ? '' : (this.cachedEditorContent.get(conversation.id) as string);
  }

  private async reloadConversation(id: number): Promise<void> {
    if (!id) {
      return;
    }

    try {
      const conversation = await this.conversationService.getConversation(id);

      // Check if conversation was expired
      if (!conversation.expirado) {
        const lastClientMessage = last(
          sortBy(
            conversation.messages.filter((x) => x.origin.id === ConversationMessageOriginEnum.Incoming),
            'date',
          ),
        );

        if (lastClientMessage && dayjs(lastClientMessage.date).isBefore(dayjs().subtract(24, 'hour'))) {
          conversation.expirado = 1;
        }
      }

      if (this.activeConversation?.id === conversation.id) {
        conversation.logs = this.activeConversation.logs;

        const temporaryMessages = this.activeConversation.messages.filter((message) => {
          const temporary = message.status?.code === ConversationMessageStatusEnum.Temporary;
          const temporaryInError = message.status?.code === ConversationMessageStatusEnum.TemporaryInError;
          return temporary || temporaryInError;
        });
        const messages = uniqBy(sortBy([...temporaryMessages, ...conversation.messages], 'date'), 'id');
        conversation.messages = messages;
        this.activeConversation = conversation;
      }

      const item = this.chatList.find((x) => x.conversation.id === conversation.id);

      if (item) {
        CrmChat.populateChatListItem(item, conversation);
      }

      this.loadConversationLogs(conversation);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  private invalidAttendant(trigger: ConversationModel): boolean {
    return (
      trigger.atendente !== undefined
      && this.chatList.find((x) => x.conversation.id === trigger.id) === undefined
      && trigger.atendente.id !== this.userContactInfo.id
    );
  }

  private validDepartment(trigger: ConversationModel): boolean {
    if (this.userContactInfo.departamentos === '' || isUndefined(this.userContactInfo.departamentos)) {
      return false;
    }
    const attendantDepartments = this.userContactInfo.departamentos.split(',');
    return attendantDepartments.includes(trigger.departamento.id.toString());
  }

  private async loadConversations(trigger?: ConversationModel): Promise<void> {
    if (
      !this.isTabAllSelected
      && trigger !== undefined
      && (this.invalidAttendant(trigger) || !this.validDepartment(trigger))
    ) {
      console.log(
        // eslint-disable-next-line max-len
        `Atualização da lista desviada por não pertencer ao usuário logado: ${this.userContactInfo.id} | ConversationID: ${trigger.id}`,
      );
      console.log(trigger);
      return;
    }

    const result = await Promise.all([
      this.conversationService.getOpenConversations(this.isTabAllSelected ? undefined : this.userContactInfo.id),
      this.conversationService.getOnHoldConversations(this.userContactInfo.id),
    ]);
    const conversations = [...result[0], ...result[1]];
    let sortedConversations = orderBy(conversations, ['dataUltimaMensagem'], ['asc']);

    // Refreshing existent chats
    const mapChat = {};
    this.chatList.forEach((chat) => {
      mapChat[chat.conversation.id] = chat;
    });
    sortedConversations = sortedConversations.map((item) => {
      const obj = item;
      const existentItem = mapChat[obj.id];
      if (existentItem) {
        obj.messages = existentItem.conversation.messages;
      }

      return obj;
    });

    this.chatList = sortedConversations.map((conversation) => {
      const item = {} as IChatListItem;
      CrmChat.populateChatListItem(item, conversation);
      if (trigger && trigger.id === item.conversation.id) {
        this.loadConversationMessages(item, true);
      }
      return item;
    });
    this.loadConversationMessagesBatch(this.userChatList);
    this.loadConversationMessagesBatch(this.onHoldChatList);
  }

  // Comentado pois entrará em testes o método acima que faz a busca de uma só vez.
  // private loadUserChatConversations(): void {
  //   this.loadConversationMessagesBatch(this.userChatList);
  //   // this.userChatList.forEach((item) => {
  //   //   this.loadConversationMessages(item, true);
  //   //   return item;
  //   // });
  // }

  // private loadOnHoldChatConversations(): void {
  //   this.onHoldChatList.forEach((item) => {
  //     this.loadConversationMessages(item, true);
  //     return item;
  //   });
  // }

  private async loadAllChatConversations(page = 1): Promise<void> {
    const nonLoadedChatList = this.allChatList
      // ignore on hold chat conversations
      .filter((x) => x.conversation.atendente && x.conversation.atendente.id && x.conversation.dataInicio)
      // ignore user chat conversations
      .filter((x) => !x.conversation.atendente || x.conversation.atendente.id !== this.userContactInfo.id);

    const pageSize = 15;
    const rangeEnd = pageSize * page;

    const splittedChatList = nonLoadedChatList.slice(rangeEnd - pageSize, rangeEnd);

    if (splittedChatList.length > 0) {
      const loadTasks: Promise<IChatListItem>[] = [];

      splittedChatList.forEach((item) => {
        loadTasks.push(this.loadConversationMessages(item, true));
        return item;
      });
      // eslint-disable-next-line no-await-in-loop
      await Promise.all(loadTasks);

      const listIdsPermitidos = new Set(this.allChatList.map((item) => item.id));
      const listIdsNovos = new Set(splittedChatList.map((item) => item.id));
      this.incrementalChatList = this.incrementalChatList.filter(
        (item) => !listIdsNovos.has(item.id) && listIdsPermitidos.has(item.id),
      );

      splittedChatList.forEach((item) => {
        if (!this.incrementalChatList.some((chat) => chat.conversation.id === item.conversation.id)) {
          this.incrementalChatList.push(item);
        }
      });
    }
  }

  private async loadConversationLogs(conversation: ConversationModel): Promise<void> {
    try {
      const logs = await this.conversationService.getConversationLogs(conversation.id);
      const obj = conversation;
      obj.logs = logs;
      if (this.activeConversation && this.activeConversation.id === conversation.id) {
        this.activeConversation.logs = logs;
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  private async loadConversationMessages(item: IChatListItem, onlyLastMessage = false): Promise<IChatListItem> {
    const obj = item;
    obj.loading = true;

    try {
      const messages = await this.conversationService.getConversationMessages(
        item.conversation.waConversationId,
        onlyLastMessage,
      );
      const temporaryMessages = obj.conversation.messages.filter((message) => {
        const temporary = message.status?.code === ConversationMessageStatusEnum.Temporary;
        const temporaryInError = message.status?.code === ConversationMessageStatusEnum.TemporaryInError;
        return temporary || temporaryInError;
      });
      obj.conversation.messages = sortBy([...temporaryMessages, ...messages], 'date');
      obj.unreadCounter = CrmChat.getTotalIncomingUnreadMessage(messages);
      this.chatList = [...this.chatList];
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      obj.loading = false;
    }

    return obj;
  }

  private async loadConversationMessagesBatch(listItems: IChatListItem[]): Promise<void> {
    const listaConversationIds = listItems.map((item) => item.conversation.waConversationId);

    const allLastMessages = await this.conversationService.getOnlyLastConversationMessages(listaConversationIds);
    const mapa = new Map<number, ConversationMessageModel[]>();
    allLastMessages.forEach((item) => {
      if (mapa.has(item.waConversationId)) {
        mapa.get(item.waConversationId)?.push(item);
      } else {
        mapa.set(item.waConversationId, [item]);
      }
    });

    listItems.forEach((item) => {
      const obj = item;
      const temporaryMessages = obj.conversation.messages.filter((message) => {
        const temporary = message.status?.code === ConversationMessageStatusEnum.Temporary;
        const temporaryInError = message.status?.code === ConversationMessageStatusEnum.TemporaryInError;
        return temporary || temporaryInError;
      });

      const messages = mapa.get(obj.conversation.waConversationId);
      if (messages !== undefined) {
        obj.conversation.messages = sortBy([...temporaryMessages, ...messages], 'date');
        obj.unreadCounter = CrmChat.getTotalIncomingUnreadMessage(messages);

        this.chatList = [...this.chatList];
      }

      return obj;
    });
  }

  private async loadTemplates(): Promise<void> {
    this.templates = await this.conversationService.getTemplates(false);
  }

  private async loadSettings(): Promise<void> {
    this.settings = await this.settingsService.getSettings();
    if (this.settings.modoOperacaoUra === 'dinamico') {
      this.dialogConfig.dynamicUraMode.show = true;
    }
  }

  private async loadBlockedNumbers(): Promise<void> {
    this.blockedNumbers = await this.contactService.getBlockedNumbers();
  }

  private async loadBtnCloseNotSavingContact(): Promise<void> {
    this.btnCloseNotSavingContact = await this.conversationService.getBtnCloseNotSavingContact();
  }

  private validateMedia(files: File[], allowedTypesAndSizes: IKeyValue<number>): boolean {
    if (files.length > this.allowedMediaLength) {
      throw new Error(
        this.$t('crm.chat.messages.allowedLimitOfMediaBySendingWasAchieved', {
          limit: this.allowedMediaLength,
        }).toString(),
      );
    }

    const allowedTypes = Object.keys(allowedTypesAndSizes);

    const invalidFileType = files.find((file) => !allowedTypes.includes(file.type) && !allowedTypes.includes('*'));
    if (invalidFileType) {
      throw new Error(
        this.$t('crm.chat.messages.selectFileTypeIsInvalid', {
          invalid: `${invalidFileType.name}(${invalidFileType.type})`,
          types: allowedTypes,
        }).toString(),
      );
    }

    const invalidFileSize = files.find((file) => {
      let sizeLimit = allowedTypesAndSizes[file.type];
      if (!sizeLimit) {
        sizeLimit = allowedTypesAndSizes['*'];
      }
      return file.size > sizeLimit;
    });
    if (invalidFileSize) {
      let allowedTypesAndSize = allowedTypesAndSizes[invalidFileSize.type];
      if (!allowedTypesAndSize) {
        allowedTypesAndSize = allowedTypesAndSizes['*'];
      }
      throw new Error(
        this.$t('crm.chat.messages.selectFileSizeIsInvalid', {
          invalid: `${invalidFileSize.name}(${(invalidFileSize.size / 1024 / 1024).toFixed(2)} MB)`,
          type: invalidFileSize.type,
          limit: `${(allowedTypesAndSize / 1024 / 1024).toFixed(2)} MB)`,
        }).toString(),
      );
    }

    return true;
  }

  private static populateChatListItem(item: IChatListItem, conversation: ConversationModel): IChatListItem {
    const mapped = item;
    mapped.id = conversation.id;
    mapped.conversation = conversation;
    mapped.conversation.messages = conversation.messages || [];

    return mapped;
  }

  private static populateConversationModel(target: ConversationModel, model: ConversationModel): void {
    const obj = target;

    obj.id = model.id;
    obj.waConversationId = model.waConversationId;
    obj.numeroWhatsapp = model.numeroWhatsapp;
    obj.numeroContato = model.numeroContato;
    obj.nomeContato = model.nomeContato;
    obj.cnpj = model.cnpj;
    obj.protocolo = model.protocolo;
    obj.fechado = model.fechado;
    obj.bloqueado = model.bloqueado;
    obj.expirado = model.expirado;
    obj.dataInicio = model.dataInicio;
    obj.dataFechamento = model.dataFechamento;
    obj.dataUltimaMensagem = model.dataUltimaMensagem;
    obj.descricao = model.descricao;
    obj.engano = model.engano;
    obj.atendente = model.atendente;
    obj.departamento = model.departamento;
  }

  private static populateConversationMessageModel(
    target: ConversationMessageModel,
    model: ConversationMessageModel,
  ): void {
    const obj = target;

    obj.id = model.id;
    obj.messageId = model.messageId;
    obj.message = model.message;
    obj.contactName = model.contactName;
    obj.contactNumber = model.contactNumber;
    obj.date = model.date;
    obj.status = model.status;
    obj.statusDate = model.statusDate;
    obj.retryable = model.retryable;
    obj.errorCode = model.errorCode;
    obj.errorMessage = model.errorMessage;
    obj.forwarded = model.forwarded;
    obj.type = model.type;
    obj.origin = model.origin;
    obj.content = model.content;
  }

  private static getTotalIncomingUnreadMessage(messages: ConversationMessageModel[]): number {
    const incomingUnreadMessages = messages.filter((message) => {
      const incoming = message.origin.id === ConversationMessageOriginEnum.Incoming;
      const received = message.status.code === ConversationMessageStatusEnum.Received;
      return incoming && received;
    });
    return incomingUnreadMessages.length;
  }

  private static createMessageInstance(
    id: number,
    message: string,
    origin: ConversationMessageOriginEnum,
    type: ConversationMessageTypeEnum,
    status: ConversationMessageStatusEnum,
  ): ConversationMessageModel {
    const instance = new ConversationMessageModel();
    instance.id = id;
    instance.date = new Date();
    instance.message = message;
    instance.origin = { id: origin } as ConversationMessageOriginModel;
    instance.type = { code: type } as ConversationMessageTypeModel;
    instance.status = { code: status } as ConversationMessageStatusModel;

    return instance;
  }

  private loadNextBatch(event) {
    const { target } = event;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
      this.currentPage += 1;
      this.loadAllChatConversations(this.currentPage);
    }
  }
}
